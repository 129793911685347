<template>
    <div>
        <div class="shoppingcartholder" v-if="!currentCart.cartVisble">
            <h4 style="padding:10vh">No Cart Open</h4>
        </div>


        <div class="shoppingcartholder" v-if="currentCart.cartVisble">

            <b-row class="shoppingcartouter">
                <b-col cols="12">
                    <h4 class="cartPushin">Current Order ( {{formatPrice(totalPrice)}} )</h4>

                    <b-row v-if="cart.cartTab > 0 " class="cartPushin cartPaddbelow cartGreyText cartAlertPad"  :style="{backgroundColor:gettabColorBackground}">
                        <b-col cols="4"><h4>TAB OPEN</h4></b-col>
                        <b-col cols="4"><h4>{{formatPrice(cart.cartTab)}}</h4></b-col>
                        <b-col cols="4"><h4>LEFT : {{formatPrice(cart.cartTab - totalPrice)}}</h4></b-col>
                    </b-row>

                    <b-row class="cartPushin cartPaddbelow cartGreyText">
                        <b-col cols="7">
                            <b>Product Info</b>
                        </b-col>
                        <b-col cols="2">
                            <b>Quantity</b>
                        </b-col>
                        <b-col cols="3">
                            <b>Price</b>                    
                        </b-col>
                    </b-row>
                    <b-row :key="index" v-for="col,index in cart.items" class="shoppingcartItem cartPushin" id="printMe">

                        <b-col cols="1">
                            <div class="cartwinmeimagepadding">
                                <div class="cartwineimageholder">
                                    <img :src="getFirstImage(cart.items[index])" class="cartwineimage"/>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="4"   v-on:click="itemNotesRow(index)">

                            {{cart.items[index].name}} 
                            <font-awesome-icon v-if="userlevel=='admin'" icon="edit" class="cartdeleteicon" />
                            <br/><span v-if="cart.items[index].notes">Notes : {{cart.items[index].notes}}</span>
                            
                        </b-col>
                        <b-col cols="4" class="">

                            <div class="cartCartActionsPadding">
                            <table class="cartCartActions">
                                <tr>
                                    <td v-on:click="adjustQty(index,-1)" >
                                        <font-awesome-icon icon="minus" class="cartqtyicon"/>
                                    </td>
                                    <td>
                                        {{ cart.items[index].qty }}
                                    </td>
                                    <td  v-on:click="adjustQty(index,1)" >
                                        <font-awesome-icon icon="plus" class="cartqtyicon"/>
                                    </td>
                                </tr>
                            </table>
                            </div>

                        </b-col>
                        <b-col cols="2">
                            {{getTotalPrice(cart.items[index])}}
                            
                        </b-col>
                        <b-col cols="1">
                            <font-awesome-icon v-if="userlevel=='admin'" icon="trash" class="cartdeleteicon"  v-on:click="deleteRow(index)" />
                        </b-col>
                    </b-row>
                 

                    <b-row class="shoppingcartTotalItem cartPushin" v-if="totalItems>0">
                        <b-col cols="4">
                            <b-button block size="sm" class='shoppingCartSuccessButtonSmall' v-on:click="addNotes()">
                                Add Notes
                            </b-button>&nbsp;                             

                        </b-col>
                        <b-col cols="3" class="cartGreyText">
                            Sub total
                        </b-col>
                        <b-col cols="2">
                            {{totalItems}} Items
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(totalPrice)}}</b>
                        </b-col>
                    </b-row>
                    <!--
                    <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="totalItems>0">
                        <b-col cols="4"></b-col>
                        <b-col cols="5" class="cartGreyText">
                            Tax (VAT {{taxPercent}}%)
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(totalTax)}}</b>
                        </b-col>
                    </b-row>
                    -->
                    <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="cart.serviceCharge && totalItems>0">
                        <b-col cols="4"></b-col>
                        <b-col cols="5" class="cartGreyText">
                            Service Charge ({{servicePercent}}%)
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(serviceCharge)}}</b>
                        </b-col>
                    </b-row>
                   <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="cart.payments > 0 && totalItems>0">
                        <b-col cols="4">
                        </b-col>
                        <b-col cols="5" class="cartGreyText">
                            Payments Already Made 
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(cart.payments)}}</b>
                        </b-col>
                    </b-row>

                    <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="totalItems>0">
                        <b-col cols="4">
                            <div v-if="userlevel == 'admin'">
                                <b-button block size="sm" class='shoppingCartSuccessButtonSmall' v-on:click="overridePrice(serviceCharge,totalPrice)" v-if="!cart.pricesOverride">
                                    Adjust Price
                                </b-button>&nbsp; 
                                <b-button block size="sm" class='shoppingCartSuccessButtonSmall' v-on:click="cancelOverridePrice()" v-if="cart.pricesOverride">
                                    Cancel Price Override
                                </b-button>&nbsp; 
                            </div>
                        </b-col>
                        <b-col cols="5" class="cartGreyText">
                            <b>Total</b>
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(grandTotal)}}</b>
                        </b-col>
                    </b-row>

                   <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="cart.payments > 0 && totalItems>0">
                        <b-col cols="4"></b-col>
                        <b-col cols="5" class="cartGreyText">
                            Outstanding Due
                        </b-col>
                        <b-col cols="3">
                            <b>{{formatPrice(grandTotal - cart.payments)}}</b>
                        </b-col>
                    </b-row>                    

                    <b-row v-if="cart.pricesOverride">
                        <b-col cols="2"></b-col>
                        <b-col cols="10">
                            <h5><br/>Price Overridden<br/>{{cart.override.notes}} </h5>
                        </b-col>
                    </b-row>



                    <b-row  class="shoppingcartTotalItem noBorder cartPushin" v-if="cart.notes && totalItems>0">
                        <b-col cols="12">Notes : <b>{{cart.notes}}</b></b-col>
                    </b-row>   

                </b-col>
            </b-row>

        </div>

        <div class="shoppingcartActionButtons" v-if="currentCart.cartVisble && totalItems>0">
            <b-row class="text-center">
                <b-col cols="6" v-if="grandTotal > 0">
                    <b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="checkoutCustomerCart()">
                    Checkout
                    </b-button>&nbsp;                    
                </b-col>
                <b-col cols="6">
                    <b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="startTab()">
                    <span v-if="parseInt(cart.cartTab) == 0 || cart.cartTab==''">Start Tab</span><span v-if="parseInt(cart.cartTab) > 0">Change Tab</span>
                    </b-button>&nbsp;                    
                </b-col>
                <b-col cols="6">
                    <b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="saveCustomerOrder()">
                    Save Order
                    </b-button>&nbsp;                    
                </b-col>
                <b-col cols="6">
                    <b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="print()">
                    Print to Kitchen
                    </b-button>&nbsp;                    
                </b-col>
                <b-col cols="6" v-if="noOfCarts > 1">
                    <b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="mergeOrder()">
                    Merge Orders
                    </b-button>&nbsp;                    
                </b-col>
            </b-row>
            <b-row  v-if="userlevel=='admin' && hasJustWines() && cart.tableNo=='Till'">
                <b-col cols="12">
                    <br><center><b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="openBottleFromStock()">
                    Open Bottle From Stock
                    </b-button>&nbsp;                    </center>
                </b-col>
            </b-row>
            <b-row  v-if="userlevel=='admin'">
                <b-col cols="12">
                    <br><center><b-button block size="lg" class='shoppingCartSuccessButton' v-on:click="openPaidByInvoice()">
                    Set Paid By Invoice
                    </b-button>&nbsp;                    </center>
                </b-col>
            </b-row>            
        </div>

        <br/><br/><br/><br/><br/><br/><br/>


        <cart-item-notes-modal
            :record="cartrecord"
            :showdialog="cartItemNotesVisible"
            v-on:doCancelItemCartNotes="doCancelItemCartNotes"
            v-on:doSaveCartItemNotes="doSaveCartItemNotes"
        />


    </div>
</template>

<script>

  import CartItemNotesModal from '@/components/cart/cartItemNotesModal.vue';

  export default {
    props: {
      showList: Boolean,
      showCustomer: Boolean,      
      wineList: Array,      
      cart:Object,
      userlevel:String,
      noOfCarts:Number,
    },
    data:() => ({
        taxPercent: process.env.VUE_APP_VAT_RATE,
        servicePercent: process.env.VUE_APP_SERVICE_CHARGE,
        imagePath:process.env.VUE_APP_DEFAULT_IMAGE_PATH,
        tabAlertPrec:process.env.VUE_APP_TAB_ALERT_PERC,
        cartItemNotesVisible: false,
        cartrecord: {}
    }),    
    components:{
        CartItemNotesModal,
    },
    computed: {
        
        currentCart() {
            return this.cart
        },
        totalPrice() {

            var totalPrice = 0;
            this.cart.items.forEach(shoppingcartItem => {
                totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
            });

            if (this.cart.pricesOverride) {
                totalPrice = parseFloat(this.cart.override.itemTotal);
            }

            return totalPrice;

        },
        totalPriceFood() {

            var totalPrice = 0;
            this.cart.items.forEach(shoppingcartItem => {
                if (shoppingcartItem.type != 'wine') {
                    totalPrice = totalPrice + (parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
                }                
            });


            return totalPrice;

        },        
        totalItems() {

            var totalItems = 0;
            this.cart.items.forEach(shoppingcartItem => {
                totalItems = totalItems + parseInt(shoppingcartItem.qty);
            });

            return totalItems;

        },
        totalTax() {

            var totalPrice = this.totalPrice;

            var calcedTAX = ( totalPrice / (100 + parseFloat(process.env.VUE_APP_VAT_RATE)) ) * parseFloat(process.env.VUE_APP_VAT_RATE);

            return calcedTAX;

        },
        serviceCharge() {

            var totalPrice = this.totalPrice;

            var serviceCharge = totalPrice  * parseFloat(parseFloat(process.env.VUE_APP_SERVICE_CHARGE) / 100);

            if (this.cart.pricesOverride) {
                serviceCharge = parseFloat(this.cart.override.serviceCharge);
            }            
            
            return serviceCharge;

        },
        grandTotal() {

            var grandTotal = this.totalPrice;
            var serviceCharge = this.serviceCharge;
            
            

            if (this.cart.serviceCharge) {
                grandTotal = ( grandTotal + serviceCharge) ;
            }

            return grandTotal;

        },
        gettabColorBackground() {
            
            var value = this.cart.cartTab - this.totalPrice;

            if (value < 0 ) {
                return "red !important";
            } else {

                var percLeft = ( value / this.cart.cartTab) * 100;
                
                console.log(percLeft);

                if (percLeft < parseFloat(this.tabAlertPrec) ) {
                    return "#ff8c00 !important";
                } else {
                    return "transparent !important";
                }

                
            }

        },    

    },
    methods: {
        mergeOrder() {
            console.log(this.cart);
            this.$emit('mergeOrder',this.cart.orderNo);
        },
        print () {
            this.$emit('printCustomerOrder');
        },
        hasJustWines() {
            var retVal  = true;
            this.cart.items.forEach(shoppingcartItem => {
                if (shoppingcartItem.type != 'wine' ) {
                    retVal  = false;
                }
            });
            return retVal;

        },                
        formatPrice(value) {
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(value));
        },
        checkoutCustomerCart() {
            this.$emit('checkoutCustomerCart');
        },        
        openBottleFromStock() {
            this.$emit('openBottleFromStock');
        },
        openPaidByInvoice() {
            this.$emit('openPaidByInvoice');
        },
        overridePrice(service,total) {
            console.log(service,total)
            this.$emit('overridePrice',service,total);
        },
        addNotes() {
            this.$emit('addNotes');
        },
        cancelOverridePrice() {
            this.$emit('cancelOverridePrice');
        },
        startTab() {
            this.$emit('doStartTab');
        },
        cancelTab() {
            this.$emit('cancelTab');
        },
        getFirstImage(cart) {

            var retimage = '/img/nowineimage.jpg';
            if (cart.type == 'food') {
                retimage = '/img/nofoodimagewhite.png';
            }

            /*
            this.wineList.forEach(wine => {
                if (wine.id == cart.id) {

                    if (wine.images) {
                        if (Object.keys( wine.images ).length > 0) {
                            retimage = this.imagePath + wine.images[0].name;
                        }
                    }
                }
                
            });
            */
            return retimage;

        },        
        getTotalPrice(shoppingcartItem) {
            var winePrice = '';

            if ( parseInt(shoppingcartItem.qty) > 0 ) {
              winePrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(shoppingcartItem.price)*parseFloat(shoppingcartItem.qty));
            }

            return winePrice;
        },       
        getPrice(shoppingcartItem) {
            var winePrice = '';

            if ( parseInt(shoppingcartItem.qty) > 0 ) {
              winePrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(shoppingcartItem.price));
            }

            return winePrice;
        },      

        somethingToBuy() {
            var retBool = false;
            this.cart.items.forEach(item => {
                if (parseInt(item.qty) > 0 ) {
                    retBool = true;
                }                
            });
            return retBool;
        },

        adjustQty(index,by) {

            console.log(this.cart.items[index]);
            if (this.cart.items[index].type == 'wine') {
                this.wineList.forEach(wine => {

                    if (this.cart.items[index].id == wine.id) {
                        this.$emit('adjustCart', wine, by , 'wine');
                    }
                    
                });

            } else {
                
                this.cart.items[index].qty = parseInt(this.cart.items[index].qty)  + by;
                if (this.cart.items[index].qty <= 0 ) {
                    this.cart.items.splice(index, 1);
                }
                this.$emit('saveCustomerOrder',false);
            }
            


        },

        saveCustomerOrder() {
            this.$emit('saveCustomerOrder',true);
        },

        itemNotesRow(index) {
            console.log(index);
            this.cartrecord = this.cart.items[index];
            this.cartItemNotesVisible  = true;
        },

        doCancelItemCartNotes() {
            this.cartItemNotesVisible  = false;
            this.$emit('saveCustomerOrder',false);             
        },

        doSaveCartItemNotes() {
            this.cartItemNotesVisible  = false;
            this.$emit('saveCustomerOrder',false);             
        },

        deleteRow(index) {
            this.cart.items.splice(index, 1);
            this.$emit('saveCustomerOrder',false);            
        }


    }
  }
</script>

<style>
    .shoppingcartholder {
        width:100%;
        border-top:2vh solid transparent;
        background-color: #2e3444;
        border-radius: 1vw;        
        color:white;
    }
    .shoppingcartholder h4{    
        color:white;
    }
    .shoppingcartItem {
        border-top:1px solid black;
        padding-top:2vh;
        padding-bottom:2vh;
    }
    .shoppingcartTotalItem {
        border-top:1px solid black;
        padding-top:2vh;
    }
    .noBorder {
        border:0;
    }
    .payButton {
        width:100%
    }
    .cartwineimage{
        width:100%;
        object-fit: cover;
    }
    .cartwineimagepadding {
        border:2vw solid transparent;
    }
    .cartwineimageholder {
        border: 1px solid #7d4848;
        height: 0;
        overflow: hidden;
        padding-top: 50%;
        box-sizing: border-box;
        position: relative;
        border-radius: 10%;  
        width:20%;
        min-height:2vw;
        min-width:2vw;
        max-height:2vw;
        max-width:2vw;
    }
    .cartwineimageholder img {
        width: 100%;
        vertical-align: top;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    .cartPushin {  
        padding-left:1vw;
        padding-right:1vw;
    }
    .cartPaddbelow {
        margin-bottom:30px;
    }
    .cartGreyText {
        color:#7f838e ;
    }

    .cartCartActions {
        color:white;
        width:100%;
    }   
    .cartCartActions td{
        text-align: center;
    }
    .cartCartActionsPadding  {
        border: 2px solid #7f838e !important;
        border-radius: 10px !important;
    }
    .shoppingcartouter {
        padding:10px;
    }
    .cartdeleteicon{
        font-size: 1vw;
    }
    .cartqtyicon {
        font-size: 0.8vw;
    }

    .shoppingCartSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
      font-size: 1.4vw !important;
      padding-left:0.5vw;
      padding-right:0.5vw;      
      min-width:12.5vw;
    }   
    .shoppingCartSuccessButtonSmall {
      background-color: #b0996f !important;
      color:white !important;
    }
    .shoppingcartActionButtons {
        border:1vw solid transparent;
    } 
    .cartAlertPad {
        margin-left:1px;
        margin-right:1px;
        border-radius: 10px;;
    }
</style>