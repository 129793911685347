<template>

<div id="container">



  <b-alert
      :show="dismissCountDown"
      fade
      :variant="dismissCountDownType"
      @dismiss-count-down="countDownChanged"
  >
      <div v-if="dismissCountDownType!='success'">Error : </div>{{errorMessage}}
  </b-alert>  
<!--<b style="color:white">{{cart}}</b>-->
  <div class="Top" v-if="!loading">

    <header-section
      :cart="cart"
      :wineListVisble="wineListVisble"
      :userlevel="userlevel"
      :userpin="userpin"
      :searchTerm="searchTerm"    
      v-on:changeFoodDrinkSwitch="changeFoodDrinkSwitch"
      v-on:showCart="doShowCart"
      v-on:hideCart="doHideCart"
      v-on:doSearchChange="doSearchChange"
      v-on:doLogout="doLogout"
      v-on:doStartCart="doStartCart"

    ></header-section>     

  </div><b>{{noOfPings}}</b>
  <div class="Container"  v-if="currentRouteName == 'home' && !loading">
      <div class="Left">

        <div id="sidebar" v-if="wineListVisble && showFoodDrink">
          <div class="sidebarholderdrinks">

            <drink-list
                :showList="wineListVisble"        
                :foodList="drinkList"
                :searchTerm="searchTerm"
                :cart="cart"
                v-on:adjustCart="adjustCart"            
            ></drink-list>
              
          </div>
          
        </div>        

        <div id="sidebar" v-if="wineListVisble && showWine">
          <div class="sidebarholder">

            <wine-filter 
              :vintages="vintages"
              :winetypes="winetypes"
              :winesizes="winesizes"
              :countries="countries"
              :grapeVariety="grapeVariety"
              v-on:doFilterChange="doFilterChange"
              ></wine-filter>
              
          </div>
          
        </div>        
      </div>
      <div class="Middle">


        <div id="content" v-if="wineListVisble && showFoodDrink">
          <div class="wineList">

            <food-list
                :showList="wineListVisble"        
                :foodList="foodList"
                :searchTerm="searchTerm"
                :cart="cart"
                v-on:adjustCart="adjustCart"            
            ></food-list>
          </div>
        </div>


        <div id="content" v-if="wineListVisble && showWine">
          <div class="wineList">

            <wine-list 
                :showList="wineListVisble"
                :wineList="wineList"
                :grapeVariety="grapeVarietyList"
                :countries="countryList"
                :wineries="wineries"
                :wineFilters="wineFilters"
                :winesizes="winesizes"
                :searchTerm="searchTerm"
                :cart="cart"
                v-on:showMoreClick="showWineDetail"
                v-on:adjustCart="adjustCart"            
            ></wine-list>        
          </div>

        </div>


      </div>
      <div class="Right">

        <div id="shoppingCart" v-if="wineListVisble">
          <div class="shoppingCartholder">
            <shopping-cart
              v-on:checkoutCustomerCart="checkoutCustomerCart"
              v-on:saveCustomerOrder="saveCustomerOrder"
              v-on:printCustomerOrder="printCustomerOrder"
              v-on:adjustCart="adjustCart"           
              v-on:addNotes="addNotes" 
              v-on:overridePrice="overridePrice" 
              v-on:cancelOverridePrice="cancelOverridePrice" 
              v-on:doStartTab="doStartTab" 
              v-on:cancelTab="cancelTab" 
              v-on:openBottleFromStock="openBottleFromStock"
              v-on:openPaidByInvoice="openPaidByInvoice"
              v-on:mergeOrder="mergeOrder"
              :userlevel="userlevel"
              :wineList="wineList"      
              :cart="cart"
              :noOfCarts="cartList.length"
              ></shopping-cart>  
              
          </div>
          
        </div>

      </div>
  </div>


  <div id="main" class="cart"  v-if="currentRouteName == 'cart'">
    <cart-list 
    :cartList="cartList"
    :userlevel="userlevel"    
    v-on:selectCart="selectCart"
    v-on:deleteCart="deleteCartRemote"
    ></cart-list>

  </div>



  <div id="main" class="cart"  v-if="currentRouteName == 'paid'">
    <cart-paid 
      :paymentList="paymentList"
      :wineList="wineList"

    ></cart-paid>

  </div>

  <div id="main"  v-if="currentRouteName == 'pay'">
 
    <payment-page
      :pageVisble="cartPaymentVisible"
      v-on:paymentStatusError="paymentStatusError"
      v-on:partPaymentSuccess="partPaymentSuccess"
      :cart="cart"
      ></payment-page>

  </div>


  <login-modal 
    v-on:onLoginClick="onLoginClick"
    :showdialog="loginModalVisible"
  /> 

  <cart-start-modal
    v-on:onStartCart="onStartCart"
    v-on:onExistCart="onExistCart"
    v-on:doCancelStartCart="doCancelStartCart"
    :showdialog="cartStartModalVisible"
    :tables="tables"
    :opencarts="cartList"
  /> 

  <cart-customer-details-modal
    :showdialog="cartCustomerDetailVisible"
    :cart="cart"
    v-on:doCancelCustomerDetails="doCancelCustomerDetails"
    v-on:doProceedCustomerPayment="doProceedCustomerPayment"
    
  /> 

  <cart-adjust-modal
    :record="cart"
    :showdialog="cartAdjustVisible"
    v-on:doCancelCartAdjust="doCancelCartAdjust"
    v-on:doSaveCartAdjust="doSaveCartAdjust"
  />

  <cart-notes-modal
    :record="cart"
    :showdialog="cartNotesVisible"
    v-on:doCancelCartNotes="doCancelCartNotes"
    v-on:doSaveCartNotes="doSaveCartNotes"
  />

  <cart-tab-modal
    :record="cart"
    :showdialog="cartTabVisible"
    v-on:doCancelTab="doCancelTab"
    v-on:doSaveTab="doSaveTab"
  />
  <merge-order-modal
    :cartList="cartList"
    :showdialog="mergeOrderVisible"
    :mergeOrderTarget="mergeOrderTarget"
    v-on:doCancelMergeOrder="doCancelMergeOrder"    
    v-on:mergeOrderSelected="mergeOrderSelected"
  />



</div>



</template>

<script>

import LoginModal from './components/user/loginModal.vue';
import WineList from './components/wine/WineList.vue';
import FoodList from './components/food/FoodList.vue';
import DrinkList from './components/food/DrinkList.vue';
import PaymentPage from './components/cart/PaymentPage.vue';
import ShoppingCart from './components/cart/ShoppingCart.vue';
import WineFilter from './components/sections/WineFilter.vue';
import HeaderSection from './components/sections/HeaderSection.vue';
import { HTTP } from './plugins/http-common';
import CartStartModal from './components/cart/cartStartModal.vue';
import CartAdjustModal from './components/cart/cartAdjustModal.vue';
import CartNotesModal from './components/cart/cartNotesModal.vue';
import CartTabModal from './components/cart/cartTabModal.vue';
import MergeOrderModal from './components/cart/mergeOrderModal.vue';
import CartCustomerDetailsModal from './components/cart/cartCustomerDetailsModal.vue';
import CartList from './components/cart/CartList.vue';
import CartPaid from './components/cart/CartPaid.vue';
//import offline from 'v-offline';

export default {
  name: 'App',
  components:{
    LoginModal,
    WineList,
    WineFilter,
    HeaderSection,
    ShoppingCart,
    PaymentPage,
    CartStartModal,
    CartCustomerDetailsModal,
    FoodList,
    DrinkList,
    CartList,
    CartPaid,
    CartAdjustModal,
    CartTabModal,
    MergeOrderModal,
    CartNotesModal
//    offline
  },
  data () {  
    return {
        // User
        token:'',
        userlevel:'',
        userpin:'',
        

        // Json sources
        wineList: [],   

        wineries: [],

        tables: [],


        loading:true,


        countryList: [],
        winesizeList: [],
        grapeVarietyList: [],
        winetypeList: [],
        foodList: [],
        drinkList: [],
        cartList: [],
        paymentList: [],

        //Calced data
        vintages: [],
        countries: [],
        winetypes: [],   
        winesizes: [],     
        grapeVariety: [],                

        // Filters
        wineFilters: {
          vintageSelected: [],
          winesizeSelected:[],
          winetypeSelected: [],
          countrySelected: [],
          grapeSelected: [],
          winePriceRange:[0,10000],
        },

        // Search
        searchTerm:'',

        debug:false,

        startCartHold: [],

        // Shopping Cart
        cart:{
          customer:{
            customerName:'',
            customerEmail:'',
            customerAddress: {
              address1:'',
              address2:'',
              address3:'',
              town:'',
              county:'',
              country:'',
              postalcode:'',
            }
          },
          uuid:'',
          orderNo : '',
          tableNo : '',          
          serviceCharge: 0,
          items:[],
          cartVisble:false,
          pricesOverride:false,
          cartTab:0,
          payments:0,
          override: {
            notes:'',
            serviceCharge: 0,
            itemTotal: '0'
          },
          notes:'',
        },

        displayWine: Object,

        errorMessage:'',
        dismissCountDown:0,
        dismissCountDownType: 'danger',

        // Merge Order
        mergeOrderTarget:0,
        mergeOrderSource:0,
        mergeOrderVisible:false,

        // control variables
        showWine:true,
        showFoodDrink:false,        
        cartStartModalVisible:false,
        cartAdjustVisible:false,
        cartNotesVisible:false,
        cartTabVisible:false,
        loginModalVisible: false,
        wineListVisble:false,
        cartCustomerDetailVisible: false,
        cartPaymentVisible: false,
        
        
        sidebarVisble:true,

        isOnline:true,
        checkSumInterval:null,
        checkSum:'',

        noOfPingsBeforeLogout: 150,
        noOfPings:0,


    };    
  },
  beforeDestroy: function() {
      clearInterval(this.checkSumInterval);
  },  
  mounted() {

    console.info('Mounted Router', this.$route);    


    // start the timer
    this.checkSumInterval = setInterval(function () {
        this.checkChecksum();
    }.bind(this), 4000);        

    if (localStorage.token) {
      this.token = localStorage.token;
      this.userlevel = localStorage.userlevel;
      this.userpin = localStorage.userpin;
    }

    if (this.token == '') {
      this.loginModalVisible =true;
      this.wineListVisble =false;
    } else {
      this.loginModalVisible =false;
      this.wineListVisble =true;
      if (Object.keys( this.wineList ).length  == 0) {

        if (localStorage.wineList) {  this.wineList = JSON.parse(localStorage.wineList);          this.loading = false; }
        if (localStorage.tables) {  this.tables = JSON.parse(localStorage.tables); }
        if (localStorage.foodList) {  this.foodList = JSON.parse(localStorage.foodList); }
        if (localStorage.drinkList) {  this.drinkList = JSON.parse(localStorage.drinkList); }
        if (localStorage.cartList) {  this.cartList = JSON.parse(localStorage.cartList); }
        if (localStorage.paymentList) {  this.paymentList = JSON.parse(localStorage.paymentList); }

        if (localStorage.vintages) {  this.vintages = JSON.parse(localStorage.vintages); }
        if (localStorage.winesizes) {  this.winesizes = JSON.parse(localStorage.winesizes); }
        if (localStorage.countries) {  this.countries = JSON.parse(localStorage.countries); }
        if (localStorage.winetypes) {  this.winetypes = JSON.parse(localStorage.winetypes); }
        if (localStorage.grapeVariety) {  this.grapeVariety = JSON.parse(localStorage.grapeVariety); }

        this.getWineData();
        this.getWineLookupData();
      }
    }

  },
  methods: { 
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    selectCart(cart) {
      console.log('selectCart',cart);
      cart.data.payments = cart.payments;
      this.cart = cart.data;
      this.$router.push({ name: 'home' });
    },
    showWineDetail(wine) {
      this.wineListVisble  =false;
      this.wineDetailVisble = true;
      this.displayWine = wine;
    },
    doFilterChange(filters) {
      this.wineFilters = filters;
    },
    doSearchChange(search) {
      this.searchTerm = search;
    },
    onLoginClick (value) {
      this.loginModalVisible = false;

      HTTP.post(
        'api/login',
        {
          user_code: value.terminalId,
          password: value.terminalPassword
        }
        )
      .then(response => {
        
        console.log(response.data)

        this.token = response.data.token;
        this.userlevel = response.data.level;
        this.userpin = response.data.usercode;
        localStorage.clear();        
        localStorage.removeItem('token');
        localStorage.removeItem('userlevel');
        localStorage.removeItem('userpin');
        localStorage.token = this.token;
        localStorage.userlevel = this.userlevel;
        localStorage.userpin = this.userpin;
        this.$nextTick(() => {
          this.getWineData();
          this.getWineLookupData(); 
        })                  

        this.wineListVisble =true;

        this.noOfPings = 0;

      })
      .catch(e => {

        this.dismissCountDown = 5;
        this.errorMessage = 'Invalid Login' + e;
        this.dismissCountDownType = 'danger';


        this.$nextTick(() => {
          this.loginModalVisible = true;
          this.wineListVisble =false;          
        })        
      })

    },
    changeFoodDrinkSwitch(showWine,showFoodDrink) {
      this.showFoodDrink = showFoodDrink;
      this.showWine = showWine;
      console.log(this.showFoodDrink,this.showWine);
      this.noOfPings = 0;
    },
    doLogout() {
        console.log('logout',localStorage);
        this.token = '';
        this.userlevel = '';
        this.userpin = '';
        this.noOfPings = 0;
        localStorage.removeItem('token');
        localStorage.removeItem('userlevel');
        localStorage.removeItem('userpin');
        localStorage.clear();
        console.log('logout',localStorage);
        this.$router.go();
    },
    getWineData() {

      HTTP.get(
          '/api/wineinfo',
          {headers: {
              Authorization: 'Bearer ' + this.token,
              Accept: 'application/json'
            }}
          )
      .then(response => {
          
          this.wineList = response.data.wines;
          this.tables =  response.data.tables;
          this.foodList =  response.data.foods;
          this.drinkList =  response.data.drinks;
          this.cartList = response.data.carts;
          this.paymentList = response.data.payments;

          // Save to local storage to speed up page load times

          localStorage.wineList = JSON.stringify(this.wineList);
          localStorage.tables = JSON.stringify(this.tables);
          localStorage.foodList = JSON.stringify(this.foodList);          
          localStorage.drinkList = JSON.stringify(this.drinkList);          
          localStorage.cartList = JSON.stringify(this.cartList);          
          localStorage.paymentList = JSON.stringify(this.paymentList);               



          this.checkActiveCart();

          this.noOfPings = 0;
          this.loading = false;

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Error getting the wine list : ' + e;
          this.dismissCountDownType = 'danger';

          console.log(e);

          //this.doLogout();

      })

    },

    paymentStatusError(message) {
      this.dismissCountDown = 5;
      this.errorMessage = message;
      this.dismissCountDownType = 'danger';
    },

    getWineLookupData() {

      HTTP.get(
          '/api/winelookupinfo',
          {headers: {
              Authorization: 'Bearer ' + this.token,
              Accept: 'application/json'
            }}
          )
      .then(response => {
          
          this.grapeVarietyList = response.data.grapevariety;
          this.wineries = response.data.wineries;        
          this.countryList = response.data.countryregion;  
          this.winetypeList = response.data.winetypes;  
          this.winesizeList = response.data.winesizes;  

          console.log('winelookupinfo',response);

          this.vintages = this.calcVintages();
          this.winesizes = this.calcWineSizes();
          this.countries = this.calcCountries();
          this.winetypes = this.calcWineTypes();
          this.grapeVariety = this.calcGrapeVariety();


          localStorage.vintages = JSON.stringify(this.vintages);
          localStorage.winesizes = JSON.stringify(this.winesizes);
          localStorage.countries = JSON.stringify(this.countries);          
          localStorage.winetypes = JSON.stringify(this.winetypes);          
          localStorage.grapeVariety = JSON.stringify(this.grapeVariety);          
          this.noOfPings = 0;

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Error getting the wine list : ' + e;
          this.dismissCountDownType = 'danger';
          console.log(e);

          //this.doLogout();

      })

    },



    openPaidByInvoice(){
      this.openPaidByInvoiceSave(this.cart);
    },

    openPaidByInvoiceSave(cart){

      // Send to back end to get the ID
      HTTP.post(
          'api/cart/paidbyinvoice',
          {
              cart: cart,
          },
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
      )
      .then(response => {
          
          console.log(response.data);
          this.noOfPings = 0;          

          this.dismissCountDown = 5;
          this.errorMessage = 'Cart Paid By Invoice';
          this.dismissCountDownType = 'success';


      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Opening Bottle : ' + e;
          this.dismissCountDownType = 'danger';


      })          


    },



    openBottleFromStock(){
      this.openBottleFromStockSave(this.cart);
    },

    openBottleFromStockSave(cart){

      // Send to back end to get the ID
      HTTP.post(
          'api/cart/openbottle',
          {
              cart: cart,
          },
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
      )
      .then(response => {
          
          console.log(response.data);
          this.noOfPings = 0;          

          this.dismissCountDown = 5;
          this.errorMessage = 'Bottle Sent to CRM as OPEN';
          this.dismissCountDownType = 'success';


      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Opening Bottle : ' + e;
          this.dismissCountDownType = 'danger';


      })          


    },


    updateCartRemote(cart) {
      // Send to back end to get the ID
      HTTP.post(
          'api/cart/update',
          {
              cart: cart,
          },
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
          )
      .then(response => {
          
          console.log(response.data);
          this.cartList = response.data.carts;
          this.checkSum = response.data.checksum;
          this.paymentList = response.data.payments;
          this.noOfPings = 0;          

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Saving Cart : ' + e;
          this.dismissCountDownType = 'danger';


      })          
    },
    saveCustomerOrder(alert) {
        this.updateCartRemote(this.cart);
        if (alert) {
          this.dismissCountDown = 5;
          this.errorMessage = 'Order saved';
          this.dismissCountDownType = 'success';        
        }
    },

    partPaymentSuccess(paymentMade) {
        this.cart.payments = paymentMade;
        this.updateCartRemote(this.cart);
    },

    printCustomerOrder() {
      // Send to back end to get the ID
      HTTP.post(
          'api/printlabel',
          {
              cart: this.cart,
          },
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
          )
      .then(response => {
          
          console.log(response.data);

          this.noOfPings = 0;

          this.dismissCountDown = 5;
          if (response.data.label ==='') {
            this.errorMessage = 'Label not printed as nothing new to print';
          } else {
            this.errorMessage = 'Label Printed';
          }
          this.dismissCountDownType = 'success';

      })
      .catch(e => {

          console.log(e);

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Printing Label : ' + e;
          this.dismissCountDownType = 'danger';


      })          

    },

    deleteCartRemote(cartID) {
      console.log(cartID);
      HTTP.delete(
          'api/cart/delete/'+cartID,
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
          )
      .then(response => {
          
          console.log(response.data);
          this.cartList = response.data.carts;
          this.checkSum = response.data.checksum;

          this.noOfPings = 0;

          var cartactivedelete = true;
          this.cartList.forEach(cartItem => {

            console.log(cartItem,this.cart.orderNo)
            if (cartItem.id ==  this.cart.orderNo ) {
              cartactivedelete = false;
            }
            
          });

          if (cartactivedelete) {
            this.cart.cartVisble = false;
          }
          

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Deleting Cart : ' + e;
          this.dismissCountDownType = 'danger';

      })            
    },

    doCancelCartAdjust() {
      this.cartAdjustVisible = false;
      this.noOfPings = 0;
    },


    doSaveCartAdjust(form) {

      this.cart.override.serviceCharge = form.serviceCharge;
      this.cart.override.itemTotal = form.itemTotal;
      this.cart.override.notes = form.notes;

      this.cart.pricesOverride = true;

      this.updateCartRemote(this.cart);

      this.cartAdjustVisible = false;

      this.noOfPings = 0;

    },

    overridePrice(service,total) {
      this.cart.override.serviceCharge = service;
      this.cart.override.itemTotal = total;
      this.cartAdjustVisible = true;


      this.noOfPings = 0;

      this.saveCustomerOrder(false);

    },
    cancelOverridePrice() {
      this.cart.pricesOverride = false;

      this.saveCustomerOrder(false);
    },


    addNotes() {

      this.cartNotesVisible = true;


      this.noOfPings = 0;

      this.saveCustomerOrder(false);

    },

    doCancelCartNotes() {
      this.cartNotesVisible = false;
      this.noOfPings = 0;
    },

    doSaveCartNotes(form) {

      this.cart.notes = form.notes;

      this.updateCartRemote(this.cart);

      this.cartNotesVisible = false;

      this.noOfPings = 0;

    },


    doStartTab() {
      this.cartTabVisible = true;
    },

    doCancelTab() {
      this.cartTabVisible = false;
    },

    doSaveTab(form) {

      this.cart.cartTab = form.tab;
      this.cartTabVisible = false;
      this.saveCustomerOrder(false);
      
    },

    cancelTab() {
      this.cart.cartTab = 0;
      this.saveCustomerOrder(false);
    },



    mergeOrder(target) {
        console.log('target');
        console.log(target);
        this.mergeOrderTarget = target;
        this.mergeOrderSource = 0;
        this.mergeOrderVisible = true;

    },
    mergeOrderSelected(source) {
        console.log('source');
        console.log(source);
        this.mergeOrderSource = source;
        this.mergeOrderVisible = false;

        this.cartList.forEach(cartitem => {

          if (cartitem.id == this.mergeOrderSource) {

            cartitem.data.items.forEach(cartdataitem => {
              console.log(cartdataitem.id,cartdataitem.qty,cartdataitem.type);
              var adjustitemObject=[];
              if (cartdataitem.type == 'food' ) {
                this.foodList.forEach(food => {

                  if (food.id == cartdataitem.id) {
                    adjustitemObject=food;
                  }
                  
                });
              } else if (cartdataitem.type == 'drink') {
                this.drinkList.forEach(drink => {

                  if (drink.id == cartdataitem.id) {
                    adjustitemObject= {
                      name: cartdataitem.name,
                      price: cartdataitem.price,
                      id: drink.id,
                    };

                    console.log(adjustitemObject);
                  }
                  
                });              
              } else {
                this.wineList.forEach(wine => {

                  if (wine.id == cartdataitem.id) {
                    adjustitemObject=wine;
                  }
                  
                });

              }
              if (adjustitemObject) {
                
                this.adjustCart(adjustitemObject,cartdataitem.qty,cartdataitem.type);
              }
              
              
            });
            this.updateCartRemote(this.cart);
            this.deleteCartRemote(this.mergeOrderSource) 
          }
          
        });

    },
    doCancelMergeOrder() {
        this.mergeOrderTarget = 0;
        this.mergeOrderSource = 0;
        this.mergeOrderVisible = false;
    },

    adjustCart(cartItem,moveBy,type) {

      console.log(cartItem,moveBy,type);
      var totalItems = 0;
      this.noOfPings = 0;

      var inList = false;
      this.cart.items.forEach(item => {
        if (type == 'drink') {
          if (item.id == cartItem.id && item.name == cartItem.name ) {inList = true;}        
        } else {
          if (item.id == cartItem.id ) {inList = true;}        
        }
        
      });

      if (!inList) {
        if (type=='wine') {
          this.cart.items.push({id:cartItem.id,uuid:this.$uuid.v4(),qty:0,name:cartItem.name,price: cartItem.retail_price, type: type, notes: ''}) 
        } else {
          this.cart.items.push({id:cartItem.id,uuid:this.$uuid.v4(),qty:0,name:cartItem.name,price: cartItem.price, type: type, notes: ''}) 
        }
        
      }

      this.cart.items.forEach(item => {

        if (type == 'drink') {

          if (item.id == cartItem.id && item.type == type  && item.name == cartItem.name ) {
            item.qty = parseInt(item.qty) + moveBy;

            if (item.qty < 0 ) { item.qty = 0 }

          }            


        } else {

          var orginialQuantity = item.qty;

          if (item.id == cartItem.id && item.type == type ) {
            item.qty = parseInt(item.qty) + moveBy;

            if (item.qty < 0 ) { item.qty = 0 }

            if (type=='wine') {

              // count how many other carts have this wine and add it to this total
              var othercartcount = 0;
              this.cartList.forEach(cart => {


                if (cart.data.tableNo != this.cart.tableNo) {
                  cart.data.items.forEach(cartsubitem => {
                      if (cartsubitem.id == cartItem.id && cartsubitem.type == type ) {
                        othercartcount = othercartcount + cartsubitem.qty;
                      }
                  });
                }
                
              });


              if ((item.qty+othercartcount) > parseInt(cartItem.released) ) { 
                
                item.qty = orginialQuantity; 
                
                this.dismissCountDown = 5;
                this.errorMessage = 'Sorry there are only ' + cartItem.released + ' bottles of that wine available, this could be assigned to another table.';
              
              }

            }          


          }            


        }


        totalItems = totalItems + item.qty;        
      });

      this.cart.totalitems = totalItems;


      if (!this.cart.cartVisble) {
        this.startCartHold = this.cart.items;
        this.doStartCart();
      } else {
        this.updateCartRemote(this.cart);
      }

    },
    
    doStartCart() {
      this.cartStartModalVisible = true;
      this.noOfPings = 0;
    },

    doCancelStartCart() {
      this.cartStartModalVisible = false;
      this.noOfPings = 0;      
    },
    onStartCart(cart) {
      this.cartStartModalVisible = false;
      this.cart.uuid = this.$uuid.v4();

      this.cart.orderNo = cart.id;
      this.cart.tableNo = cart.table_name;
      this.cart.serviceCharge = cart.service_charge;
      this.noOfPings = 0;      

      this.cart.pricesOverride= false;
      this.cart.cartTab = 0;
      this.cart.payments = 0;
      this.cart.notes = '';
      this.cart.override = {
            notes:'',
            serviceCharge: 0,
            itemTotal: '0'
          };


      this.cart.items = [];

      console.log('this.startCartHold');
      console.log(this.startCartHold);
      if (this.startCartHold.length > 0) {
        this.cart.items = this.startCartHold;
        this.startCartHold = [];
      }
      this.cart.cartVisble = true;
      console.log(this.cart);
      this.updateCartRemote(this.cart);      
    },

    onExistCart(cart){
      console.log('cart');
      console.log(cart);
      this.cartStartModalVisible = false;
      this.cart = cart.data;
      if (this.startCartHold.length > 0) {
        this.cart.items.push(this.startCartHold[0]);
        this.startCartHold = [];
      }
      this.cart.cartVisble = true;
      this.noOfPings = 0;      
      console.log(this.cart);
      this.updateCartRemote(this.cart);         
    },

    doShowCart() {
      console.log('showCart');
      this.wineListVisble = false;
      this.cartVisble = true;
      this.cartCustomerDetailVisible = false;
      this.cartPaymentVisible = false;
      this.noOfPings = 0;      
    },

    checkoutCustomerCart() {
      console.log('checkoutCustomerCart');
      this.cartCustomerDetailVisible = true;
      this.noOfPings = 0;
    },

    doCancelCustomerDetails() {
      console.log('doCancelCustomerDetails');            
      this.cartCustomerDetailVisible = false;
    },


    doProceedCustomerPayment(name,email) {
      this.cartCustomerDetailVisible = false;      
      this.cart.customer.customerName = name;
      this.cart.customer.customerEmail = email;
      this.noOfPings = 0;      
      this.updateCartRemote(this.cart);         
      this.$router.push({ name: 'pay' });

    },

    cartShowPaymentPage(customerName,customerEmail) {

      console.log('cartShowPaymentPage',customerName,customerEmail);

      this.cart.customer.customerName = customerName;
      this.cart.customer.customerEmail = customerEmail;
      this.noOfPings = 0;

      // Save the cart to the backend and get the state ID


      console.log(this);

      HTTP.post(
          'api/payment/create',
              {
                  data: this.cart,
              },
              {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
              }               
          )
      .then(response => {

          this.cart.uuid= response.data.uuid;
          this.wineListVisble = false;
          this.cartVisble = false;
          this.cartCustomerDetailVisible = false;
          this.cartPaymentVisible = true;

      })
      .catch(e => {


          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry we cannot take the payment at the moment : ' + e;
          this.dismissCountDownType = 'danger';



      })          


    },

    doHideCart() {
      console.log('hideCart');
      this.noOfPings = 0;      
      this.wineListVisble = true;
      this.cartVisble = false;      
      this.cartCustomerDetailVisible = false;
      this.cartPaymentVisible = false;
    },



    // Calc lookup datas
    calcVintages() {
      var results = [];
      this.wineList.forEach(wine => {
        
        var found= false;
        for (let index = 0; index < results.length; index++) {
          if ( results[index].name == wine.vintage ) {
              results[index].qty = results[index].qty + 1;
              found=true;
          }          
        }

        if (!found) {
          results.push({name:wine.vintage,qty:1,display:''});
          console.log('wine.vintage',wine.vintage)
        }        

        
      });
      results.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });    

      
      for (let index = 0; index < results.length; index++) {      
          results[index].display = results[index].name;
      }
      

      return results;
    },    



    // Calc lookup datas
    calcWineTypes() {

      var results = [];
      this.wineList.forEach(wine => {

        var sourceListResult  = this.winetypeList.filter(function(o){return o.id == wine.wine_style_id;} );

        if (sourceListResult) {

          //Now see if it is in the lookup list

          var targetListResult  = results.filter(function(o){return o.id == wine.wine_style_id;} );
          if ( targetListResult.length ===0) {
            if (sourceListResult[0]) {
              results.push(sourceListResult[0]);
            }
          }


        }

      });

      results.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });    

      return results;
    },


    calcGrapeVariety() {

      var results = [];
      this.wineList.forEach(wine => {

        var sourceListResult  = this.grapeVarietyList.filter(function(o){return o.id == wine.grape_variety.id;} );

        if (sourceListResult) {

          //Now see if it is in the lookup list
          var targetListResult  = results.filter(function(o){return o.id == wine.grape_variety.id;} );
          if ( targetListResult.length ===0) {
            if (sourceListResult[0]) {
              results.push(sourceListResult[0]);
            }
          }


        }

      });

      results.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });    

      return results;
    },

    calcWineSizes() {

      var results = [];
      this.wineList.forEach(wine => {

        var sourceListResult  = this.winesizeList.filter(function(o){return o.id == wine.wine_size_id;} );

        if (sourceListResult) {

          //Now see if it is in the lookup list

          var targetListResult  = results.filter(function(o){return o.id == wine.wine_size_id;} );
          if ( targetListResult.length ===0) {
            if (sourceListResult[0]) {
              results.push(sourceListResult[0]);
            }
          }


        }

      });

      results.sort(function(a, b) {

        console.log(a,b);
        var nameA = '';
        var nameB = '';
        if (a.name) {
          nameA = a.name.toUpperCase();
        }
        if (b.name) {
          nameA = a.name.toUpperCase();
        }
        
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });    

      return results;
    },


    // Calc lookup datas
    calcCountries() {

      var results = [];
      this.wineList.forEach(wine => {

        if (wine.winery) {
          if (wine.winery.country) {

            var sourceListResult  = this.countryList.filter(function(o){return o.id == wine.winery.country;} );

            if (sourceListResult) {

              //Now see if it is in the lookup list
              var targetListResult  = results.filter(function(o){return o.id == wine.winery.country;} );
              if ( targetListResult.length ===0) {
                var obj = {id: sourceListResult[0].id, name: sourceListResult[0].name}
                results.push(obj);
              }


            }
          }
        }

      });

      results.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });    

      console.log('calcCountries')
      console.log(results);

      return results;
    },
    handleConnectivityChange(status) {
      console.log(status);
      this.isOnline = status;
    },
    checkChecksum() {
      

      HTTP.get(
          '/api/ping',
          {headers: { Accept: 'application/json' }}
          )
      .then(response => {
          window.scrollTo(0,0);
          if (this.checkSum == '') {
            this.checkSum = response.data.checksum
          }
          if (this.checkSum !== response.data.checksum) {
            this.checkSum = response.data.checksum;
            this.noOfPings = 0;
            this.getWineData();
          } else {
            if (this.$route.name == 'home') {
              this.noOfPings = this.noOfPings + 1;
              if (this.noOfPings > this.noOfPingsBeforeLogout && this.$route.name == 'home') {
                this.doLogout();
              }
            } else {
              this.noOfPings = 0;
            }
          }

      });

    }, 
    checkActiveCart() {

        var cartDeleted = true;
        this.noOfPings = 0;        
        this.cartList.forEach(cart => {


          if (cart.data.uuid == this.cart.uuid) {
              this.cart = cart.data;
              cartDeleted = false;
          }
          

        });

        if (cartDeleted) {
          this.cart.orderNo = '';
          this.cart.tableNo = '';
          this.cart.serviceCharge = 0;

          this.cart.pricesOverride= false;
          this.cart.cartTab = 0;
          this.cart.override = {
                notes:'',
                serviceCharge: 0,
                itemTotal: '0'
              };


          this.cart.items = [];
          this.cart.cartVisble = false;
        }

    }
  },
  computed: {
        currentRouteName() {
            console.log(this.$route);
            return this.$route.name;
        }    
  }

}
</script>

<style>
  * {
      touch-action: manipulation;
  }
  html, body {
      max-width: 100%;
      overflow-y: hidden;
      overflow-x: hidden !important;      
  }


  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #131720;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    overflow: hidden;     
    border:5px solid #131720;
  }

.Top {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #131720;
    font-size: 3rem;
    position: relative;
    z-index: 10;
    height: 120px;
}
/*This is our main wrapping element, it's made 100vh high to ensure it is always the correct size and then moved into place and padded with negative margin and padding*/
.Container {
    display: flex;
    overflow: hidden;
    height: 100vh;
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    width: 100%;
    backface-visibility: hidden;
    will-change: overflow;
}
/*All the scrollable sections should overflow and be whatever height they need to be. As they are flex-items (due to being inside a flex container) they could be made to stretch full height at all times if needed.
WebKit inertia scrolling is being added here for any present/future devices that are able to make use of it.
*/
.Left,
.Middle,
.Right {
    overflow: auto;
    height: auto;
    padding: .5rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;    
    overflow-x: hidden !important;     
}
/*Entirely optional – just wanted to remove the scrollbar on WebKit browsers as I find them ugly*/
.Left::-webkit-scrollbar,
.Middle::-webkit-scrollbar,
.Right::-webkit-scrollbar {
    display: none;
}
/*  Left and Right are set sizes while the Middle is set to flex one so it occupies all remaining space. This could be set as a width too if prefereable, perhaps using calc.*/
.Left {
    width: 25vw;
    background-color: #131720;
}

.Middle {
    flex: 1;
}

.Right {
    width: 35vw;
    background-color: #131720;
}
.headerholder {
  width:100%;
}

  
</style>
