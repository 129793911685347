
<template>
  <div class="center">
    <b-modal id="bv-drink-select-modal" no-close-on-backdrop v-model="show" size="xl">
      <div class="drink-select-form">

            <h1>{{getDescription()}}</h1>

            <b-row>

                <b-col cols="6" v-if="drink.price_75 > 0">

                    <div v-bind:class="halfheight" :style="{backgroundColor:getdrinkColorBackground,color:getdrinkColorForground}">
                        <div class="drinkSummaryContent" @click="pickDrink(drink.price_75,'75ml')">      
                            <div class="drinkSummaryDescptionHolder">
                                <div class="drinkSummaryDescption drinkSummaryDescptionBoldtitle">75ml</div>
                            </div>
                            <div class="drinkSummaryPrice">{{getPrice(drink.price_75)}}</div>
                        </div>    
                    </div>

                    <br/><br/>


                </b-col>
                <b-col cols="6" v-if="drink.price_125 > 0">

                    <div v-bind:class="halfheight" :style="{backgroundColor:getdrinkColorBackground,color:getdrinkColorForground}">
                        <div class="drinkSummaryContent" @click="pickDrink(drink.price_125,'125ml')">      
                            <div class="drinkSummaryDescptionHolder">
                                <div class="drinkSummaryDescption drinkSummaryDescptionBoldtitle">125ml</div>
                            </div>
                            <div class="drinkSummaryPrice">{{getPrice(drink.price_125)}}</div>
                        </div>    
                    </div>

                    <br/><br/>

                </b-col>
                <b-col cols="6" v-if="drink.price_175 > 0">

                    <div v-bind:class="halfheight" :style="{backgroundColor:getdrinkColorBackground,color:getdrinkColorForground}">
                        <div class="drinkSummaryContent" @click="pickDrink(drink.price_175,'175ml')">      
                            <div class="drinkSummaryDescptionHolder">
                                <div class="drinkSummaryDescption drinkSummaryDescptionBoldtitle">175ml</div>
                            </div>
                            <div class="drinkSummaryPrice">{{getPrice(drink.price_175)}}</div>
                        </div>    
                    </div>

                    <br/><br/>

                </b-col>
                
                <b-col cols="6" v-if="drink.price_bottle > 0">

                    <div v-bind:class="halfheight" :style="{backgroundColor:getdrinkColorBackground,color:getdrinkColorForground}">
                        <div class="drinkSummaryContent" @click="pickDrink(drink.price_bottle,'Bottle')">      
                            <div class="drinkSummaryDescptionHolder">
                                <div class="drinkSummaryDescption drinkSummaryDescptionBoldtitle">Bottle</div>
                            </div>
                            <div class="drinkSummaryPrice">{{getPrice(drink.price_bottle)}}</div>
                        </div>    
                    </div>

                    <br/><br/>

                </b-col>
                
            </b-row>

          <center>
            <b-button block size="lg"  @click="cancelPick()">
              Cancel
            </b-button>
          </center>

          

      </div>

    </b-modal>
  </div>
</template>


<script>

  import tableImage from '@/assets/tables.png';
  import defaults from '../../plugins/defaults';  

  export default {
    props: {
      showdialog: Boolean,
      drink: Object,
      title: String
    },   
    data() {
      return {
            colors:defaults.COLORS,
            size:defaults.SIZES,          
            tableimg:  tableImage
      }
    },   
    methods: {
        pickDrink(price,size) {
            this.$emit('doPickDrink',price,size)
        },
        cancelPick() {
            this.$emit('doCancelPick')
        },
        getDescription() {            

            var drink = this.drink;
            var drinkName = drink.name;

            var extras = "";

            
            if (drink.wine_color.length>0) { 
                extras = extras + ' ' + drink.wine_color;
            }
            if (drink.year.length>0) { 
                extras = extras + ' ' + drink.year;
            }
            
            if (drink.bottle_size.length>0) { 
                extras = extras + ' ' + drink.bottle_size;
            }
                    
            if (extras  !== '') {
              drinkName = drinkName + '(' + extras + ')';
            }


            return drinkName;
        },        
        getPrice(drinkprice) {
            var drinkPrice = 'Comp';

            if (parseFloat(drinkprice) > 0 ) {
              drinkPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(drinkprice));
            }
            

            return drinkPrice;
        },        
    },
    computed: {
        show: {
            get () {
            return this.showdialog
            }
        },
        getdrinkColorBackground() {
            var color = parseInt(this.drink.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].back + " !important";
        },    
        getdrinkColorForground() {
            var color = parseInt(this.drink.color);
            if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
            return this.colors[color].color + " !important";
        },    
        halfheight() {
            console.log(this.drink); 
            if ( this.drink.size == 2) {
              return  'drinkSummarySquare'
            } else {
              return 'drinkSummarySquare'
            }
        },           
    }    
  }
</script>

<style>
    #bv-drink-select-modal .modal-content {
        background-color: #2e3442 !important;
    }

    #bv-drink-select-modal .modal-footer {
        border-top: 1px solid #2e3442;
        display:none;
    }

    .startCartSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
    }

    #bv-drink-select-modal .modal-header {
        border-bottom: 1px solid #2e3442;
    }
    #bv-drink-select-modal h1 {
        color:white;
    }

    .startCartModelButton {
        width:100px;
        border-radius: 10px !important;
        margin:5px;
        font-size: 1.8rem !important;
        background-color:#b0996f !important;
        color:white !important;
        border-color: #b0996f !important;        
    }



</style>