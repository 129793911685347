<template>
  <div class="center">
    <b-modal id="bv-start-cart-customer-modal" no-close-on-backdrop v-model="show">
      <template #modal-title>
        <h4 class="not-margin">
          Please enter the customers details
        </h4>
      </template>


      <div class="con-form">

     
        <b-row>
            <b-col cols="12">
                <br/><b-input placeholder="Customers Name" v-model="customerName"/><br/>
                <div class="bv-start-cart-customer-modalerrorMessage" v-if="customerNameError">Please enter the customer name</div>
            </b-col>
            <b-col cols="12">
                <br/><b-input placeholder="Customers Email" v-model="customerEmail"/><br/>
                <div class="bv-start-cart-customer-modalerrorMessage" v-if="customerEmailError">Please enter the customer Email</div>
            </b-col>
        </b-row>        


      </div>

      <template #modal-footer>
        <div class="footer-dialog">
            <b-row>
                <b-col>
                    <b-button block  @click="proceedToPayment()" class="startCartSuccessButton">
                        Continue
                    </b-button>
                </b-col>
                <b-col>
                    <b-button block  @click="skipToPayment()" class="startCartSuccessButton">
                        Skip
                    </b-button>
                </b-col>
                <b-col>
                    <b-button block  @click="doCancelCustomerDetails()">
                        Cancel
                    </b-button>
                </b-col>
            </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  export default {
    props: {
      showdialog: Boolean,
      cart: Object
    },   
    data() {
      return {
        customerName : '',
        customerNameError: false,
        customerEmail : '',
        customerEmailError: false,
        systemName:process.env.VUE_APP_DEFAULT_CUSTOMER_NAME,
        systemEmail:process.env.VUE_APP_DEFAULT_CUSTOMER_EMAIL,    
        showButtons: true,    
      }
    },   
    methods: {
        doCancelCustomerDetails() {
            this.$emit('doCancelCustomerDetails')
        },
        skipToPayment() {
            this.customerName = this.systemName;
            this.customerEmail = this.systemEmail;
            this.showButtons = false;
            this.proceedToPayment();
        },
        proceedToPayment() {

            this.customerNameError = false;
            this.customerEmailError = false;

            if (this.customerName == '') {
                this.customerNameError = true;
            }
            if (this.customerEmail == '') {
                this.customerEmailError = true;
            }

            if (!this.customerEmailError && !this.customerNameError ) {
                console.log('emit');
                this.$emit('doProceedCustomerPayment',this.customerName,this.customerEmail);
            }

        },        

    },
    computed: {
      show: {
        get () {
          return this.showdialog
        }
      }
    }    
  }
</script>

<style>
    #bv-start-cart-customer-modal .modal-content {
        background-color: #2e3442 !important;
    }


    #bv-start-cart-customer-modal .modal-footer {
        border-top: 1px solid #2e3442;
    }


    #bv-start-cart-customer-modal .modal-header {
        border-bottom: 1px solid #2e3442;
    }
    #bv-start-cart-customer-modal h4 {
        color:white;
    }

    .bv-start-cart-customer-modalerrorMessage {
      color:#cc0000;
      font-weight: 700;
    }

    .startCartSuccessButton {
      background-color: #b0996f !important;
      color:white !important;
    }


</style>